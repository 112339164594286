import { put, takeLatest } from 'redux-saga/effects';
import { dashboardTypes, dashboardCreators } from './reducer';
import {
  registerZoom,
  logToCrashalytics,
  getClassCatagoriesWeb,
  deleteQrDocWithToken,
  recordErrorInCrashalytics,
  NOTIFICATION_TYPE,
  classsSessionsStatus
} from 'tatvarnsdk/Web';

import { showToast } from '@modules/common/index';

const { REQUEST_HOME_SCREEN_DATA, REGISTER_ZOOM, DELETE_DOC_TOKEN } = dashboardTypes;
const { dataSuccess, dataFail, registerZoomEnd } = dashboardCreators;

export function* getClassesWithCategories() {
  logToCrashalytics('getClassesWithCategories => Dashboard/Saga');
  try {
    const response = yield getClassCatagoriesWeb();
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error Getting Classes';
    }
    const { categoriesWithClasses, currentUser } = response.data;
    return yield put(dataSuccess(categoriesWithClasses, currentUser));
  } catch (e) {
    yield put(dataFail());
    recordErrorInCrashalytics('getClassesWithCategories => Dashboard/Saga', e);
  }
}

export function* registertoZoom(props) {
  logToCrashalytics('registertoZoom => Dashboard/Sag.js');
  const { session } = props;
  console.log(session);
  try {
    if (session.zoomUrl) {
      if (!session.isGoodTimeToJoin) {
        showToast({
          title: 'Join a Class',
          message: 'Please join the class up to 15 minutes before the start of class only',
          notificationType: NOTIFICATION_TYPE.DEFAULT
        });
        return yield put(registerZoomEnd());
      }
      const res = window.open(session.zoomUrl);
      if (res === null) {
        showToast({
          title: 'Browser Pop Up Blocked',
          message: 'Please Try Again or Enable Pop Up from browser settings for this site!',
          notificationType: NOTIFICATION_TYPE.ERROR
        });
      }
      return yield put(registerZoomEnd());
    }
    if (!session.eligibileToJoin) {
      showToast({
        title: 'Eligibility',
        message: 'You are not elligible to join this class, check your subscription',
        notificationType: NOTIFICATION_TYPE.ERROR
      });
      return yield put(registerZoomEnd());
    }
    // if (!session.isGoodTimeToJoin) {
    //   showToast({
    //     title: 'Join a Class',
    //     message: 'Please join the class up to 15 minutes before the start of class only',
    //     notificationType: NOTIFICATION_TYPE.DEFAULT
    //   });
    //   return yield put(registerZoomEnd());
    // }
    if (session?.status === classsSessionsStatus.TEACHER_UNAVAILABLE) {
      showToast({
        title: 'Teacher Unavailable',
        message: 'Teacher of this class is not available. You can schedule/join other class of same type.',
        notificationType: NOTIFICATION_TYPE.WARNING
      });
      return yield put(registerZoomEnd());
    }
    const teacherId = session?.coveredBy ?? session.teacherRefs[0];
    const meetingId = session?.newMeetingId ?? session?.meetingId;
    console.debug('TeacherId', teacherId);
    console.debug('SessionId', session.classId);
    console.debug('MeetingId', meetingId);
    const responseFromZoom = yield registerZoom(meetingId, session.classId, teacherId, session?.id);
    if (!responseFromZoom || !responseFromZoom.success || !responseFromZoom.data) {
      throw responseFromZoom?.error ?? 'Unable to fetch url';
    }
    const { joinUrl } = responseFromZoom.data;
    const response = window.open(joinUrl);
    if (response === null) {
      showToast({
        title: 'Browser Pop Up Blocked',
        message: 'Please Try Again or Enable Pop Up from browser settings for this site!',
        notificationType: NOTIFICATION_TYPE.ERROR
      });
    }
    yield put(registerZoomEnd());
  } catch (e) {
    recordErrorInCrashalytics('registertoZoom => Dashboard/Saga.js', e);
    showToast({
      title: 'Error Getting join url!',
      message: e.message ?? e,
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    yield put(registerZoomEnd());
  }
}

export function* deleteDocToken(props) {
  const { docId } = props;
  try {
    logToCrashalytics('deleteDocToken => Dashboard/Saga.js');
    yield deleteQrDocWithToken(docId);
  } catch (e) {
    recordErrorInCrashalytics('deleteDocToken => Dashboard/Saga.js', e);
  }
}

export default function* dashboardSaga() {
  yield takeLatest(REQUEST_HOME_SCREEN_DATA, getClassesWithCategories);
  yield takeLatest(REGISTER_ZOOM, registertoZoom);
  yield takeLatest(DELETE_DOC_TOKEN, deleteDocToken);
}
